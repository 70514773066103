/**
 * @file It contains scripts for issue accesses overview.
 */
import { mapGetters, mapActions } from "vuex"
import ThemisUpdateAccess from "@/components/access/update"
import { ISSUE_ACCESS_UPDATE_ROLES } from "@/constants"

export default {
  name      : "IssueAccesses",
  components: { ThemisUpdateAccess },
  data() {
    return {
      expandedGroups         : [],
      showUpdateAccessDialog : false,
      selectedUserForEditing : undefined,
      selectedGroupForEditing: undefined
    }
  },
  computed: {
    ...mapGetters({
      accesses         : "accesses/accesses",
      isLoadingAccesses: "accesses/isLoadingAccesses",
      users            : "users/users",
      groups           : "groups/groups",
      issues           : "issues/issues",
      domains          : "domains/domains",
      groupsUsers      : "groups/groupsUsers",
      roles            : "roles/roles",
      loggedInUser     : "auth/loggedInUser"
    }),
    issue() {
      return this.issues?.find(issue => issue.id === +this.$route.params.id)
    },
    domain() {
      return this.domains?.find(domain => domain.id === this.issue?.domainId)
    },
    rolesMap() {
      const rolesMap = new Object()
      for (const role of this.roles) {
        rolesMap[role.id] = role
      }
      return rolesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.users) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    headersForUsersWithAccessTable() {
      return this.$TABLES.USERS_WITH_ISSUE_ACCESS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    headersForGroupsWithAccessTable() {
      return this.$TABLES.GROUPS_WITH_ISSUE_ACCESS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    usersWithAccess() {
      const usersWithAccess = []
      const userRolesMap    = {}
      for (const access of this.accesses) {
        const isAccessOnCurrentIssue  = access.criteria?.id === +this.$route.params.id
        const isAccessOnCurrentDomain = access.criteria?.domainId === this.issue?.domainId
        if (access.userId && (isAccessOnCurrentIssue || isAccessOnCurrentDomain)) {
          const role = {
            id      : access.roleId,
            name    : this.rolesMap[access.roleId]?.name,
            accessId: access.id
          }

          if (isAccessOnCurrentDomain) {
            role.domain = this.domain?.name
          }

          if (userRolesMap[access.userId]) {
            userRolesMap[access.userId].push(role)
          } else {
            userRolesMap[access.userId] = [role]
          }
        }
      }
      for (const [userId, roles] of Object.entries(userRolesMap)) {
        const user = this.usersMap[userId]
        let name
        let disabledButNotDeleted
        if (user) {
          name                  = user.name,
          disabledButNotDeleted = user.disabledButNotDeleted
        }
        usersWithAccess.push({
          id: +userId,
          name,
          disabledButNotDeleted,
          roles
        })
      }
      return usersWithAccess
    },
    groupsWithAccess() {
      const groupsWithAccess = []
      const groupRolesMap    = {}
      for (const access of this.accesses) {
        const isAccessOnCurrentIssue  = access.criteria?.id === +this.$route.params.id
        const isAccessOnCurrentDomain = access.criteria?.domainId === this.issue?.domainId
        if (access.groupId && (isAccessOnCurrentIssue || isAccessOnCurrentDomain)) {
          const role = {
            id      : access.roleId,
            name    : this.rolesMap[access.roleId]?.name,
            accessId: access.id
          }

          if (isAccessOnCurrentDomain) {
            role.domain = this.domain?.name
          }

          if (groupRolesMap[access.groupId]) {
            groupRolesMap[access.groupId].push(role)
          } else {
            groupRolesMap[access.groupId] = [role]
          }
        }
      }
      for (const [groupId, roles] of Object.entries(groupRolesMap)) {
        const users = this.groupsUsers[groupId]?.map(userId => ({
          id                   : userId,
          name                 : this.usersMap[userId]?.name,
          disabledButNotDeleted: this.usersMap[userId]?.disabledButNotDeleted
        }))
        groupsWithAccess.push({
          id  : +groupId,
          name: this.groupsMap[groupId]?.name,
          users,
          roles
        })
      }
      return groupsWithAccess
    },
    canUserUpdateAccess() {
      const roles = []
      for (const userWithAccess of this.usersWithAccess) {
        if (userWithAccess.id === this.loggedInUser.id) {
          roles.push(...userWithAccess.roles.map(role => role.id))
        }
      }
      for(const groupWithAccess of this.groupsWithAccess) {
        if (groupWithAccess.users) {
          for (const user of groupWithAccess.users) {
            if (user.id === this.loggedInUser.id) {
              roles.push(...groupWithAccess.roles.map(role => role.id))
            }
          }
        }
      }

      return !!roles.find(role => ISSUE_ACCESS_UPDATE_ROLES.includes(role))
    },
    usersWithoutAccess() {
      return this.users.filter(user =>
        user.enabled &&
        !this.usersWithAccess.find(userWithAccess =>
          user.id === userWithAccess.id
        ))
    },
    groupsWithoutAccess() {
      return this.groups.filter(group =>
        !this.groupsWithAccess.find(groupWithAccess =>
          group.id === groupWithAccess.id)
      )
    }
  },
  methods: {
    ...mapActions({
      addAccess   : "accesses/addAccess",
      removeAccess: "accesses/removeAccess",
      notify      : "shared/notify"
    }),
    handleAccessAdded(userOrGroup) {
      const [type, id]            = userOrGroup.split("_")
      this.showUpdateAccessDialog = false
      this.notify({
        type      : "success",
        text      : "681",
        parameters: {
          name: type === "user" ? this.usersMap[id].name : this.groupsMap[id].name
        }
      })
    },
    handleAccessUpdated(userOrGroup) {
      this.showUpdateAccessDialog  = false
      this.selectedUserForEditing  = undefined
      this.selectedGroupForEditing = undefined
      this.notify({
        type      : "success",
        text      : "695",
        parameters: {
          name: userOrGroup.name
        }
      })
    },
    handleCloseUpdateAccess() {
      this.showUpdateAccessDialog  = false
      this.selectedGroupForEditing = undefined
      this.selectedUserForEditing  = undefined
    },
    handleEditAccess(isUser, userOrGroup) {
      this.showUpdateAccessDialog = true
      if (isUser) {
        this.selectedUserForEditing = userOrGroup
      } else {
        this.selectedGroupForEditing = userOrGroup
      }
    }
  }
}